<template>
  <div
    ref="mapHolder"
    class="relative h-full w-full"
  >
    <l-map
      ref="map"
      :center="currentCenter"
      :crs="dutchCrs"
      :max-zoom="zoom.max"
      :min-zoom="zoom.min"
      :zoom="zoom.current"
      @ready="initMap"
      @update:center="centerUpdate"
      @update:zoom="zoomUpdate"
    >
      <l-tile-layer
        :attribution="mapSettings.attribution"
        :url="tileEndpoint"
      />
    </l-map>
    <div class="absolute left-[10px] top-[84px] z-[1000] flex flex-col space-y-[10px]">
      <button
        type="button"
        class="leaflet-like-button"
        @click="toggleFullscreen"
      >
        <UiIcon
          :name="`heroicons:arrows-pointing-${mapIsFullscreen ? 'in' : 'out'}`"
        />
      </button>

      <button
        v-if="drawnShape"
        type="button"
        class="leaflet-like-button"
        @click="removeDrawnLayer"
      >
        <UiIcon
          name="heroicons:trash"
        />
      </button>
    </div>
    <div class="absolute left-[50px] top-[10px] z-[1000] flex gap-1">
      <input
        v-model="search"
        type="text"
        class="leaflet-like-input"
        placeholder="Zoeken"
        @keyup.enter="performSearch"
      >
      <button
        type="button"
        class="leaflet-like-button"
        @click="performSearch"
      >
        <icon-arrow-right class="ml-1.5" />
      </button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import 'leaflet/dist/leaflet.css';
import markerImage from '../assets/images/marker.svg';
import L, {Control, Layer, Map} from 'leaflet';
import {LMap, LTileLayer} from '@vue-leaflet/vue-leaflet';
import proj4 from 'proj4';
import 'leaflet-draw';
import 'leaflet-draw/dist/leaflet.draw.css';

const props = defineProps({
  allowed: {
    type: Array,
    default: () => [],
  },
});

const {convertRDtoWSG84} = useCoordinates();

const search = ref();
function performSearch() {
  const rdCoordsPattern = /^\d+, \d+$/;
  if (rdCoordsPattern.test(search.value)) {
    const rdCoords = search.value.split(', ');
    const coords = convertRDtoWSG84(rdCoords[0], rdCoords[1]);
    goToCoordinates(coords[0], coords[1]);
    zoomUpdate(11);
  }
}

function useProjection() {
  proj4.defs('EPSG:28992', '+proj=sterea +lat_0=52.15616055555555 ' +
    '+lon_0=5.38763888888889 +k=0.9999079 +x_0=155000 +y_0=463000 ' +
    '+ellps=bessel +towgs84=565.417,50.3319,465.552,-0.398957,0.343988,-1.8774,4.0725 ' +
    '+units=m +no_defs');

  return {
    project: (coordinates: {lat: number, lng: number}) => {
      const point = proj4('EPSG:28992').forward([coordinates.lng, coordinates.lat]);
      return new L.Point(point[0], point[1]);
    },
    unproject: (point: {x: number, y: number}) => {
      const coordinates = proj4('EPSG:28992').inverse([point.x, point.y]);
      return L.latLng(coordinates[1], coordinates[0]);
    },
    bounds: L.bounds([482.06, 308914.15], [275902.39, 636381.86]),
    proj4def: proj4('EPSG:28992'),
  };
}

const projection = useProjection();

const mapHolder = ref<Element>();
const map = ref(null);
const dutchCrs = ref(null);
const drawLayer = ref<Layer>();
const drawControl = ref<Control>();
const drawnShape = ref(null);
const mapIsFullscreen = ref(false);

// const markings: [object] = [];
// const projectMarkings = 0;
// const zoomToAmount = 1;

const emit = defineEmits(['setMarking']);

const leafletMap = ref<Map>();
const mapUrl = ref('https://service.pdok.nl');
const center = ref([52.156, 5.389]);
const currentCenter = ref([52.156, 5.389]);
const zoom = ref({
  current: 8,
  min: 3,
  max: 14,
  scale: 3440.640, // Max zoomed out scale possible for this map.
  byType: {
    postcode: 12,
    weg: 11,
    adres: 10,
    wijk: 10,
    woonplaats: 8,
    gemeente: 7,
    provincie: 5,
  },
  byShape: {
    point: 8,
    polygon: 7,
    linestring: 7,
  },
});

const scales = computed(() => {
  // Calculate the zoom scaling based on the maximum zoom distance
  const scales = [];
  let scale = zoom.value.scale;
  for (let i = 0; i <= zoom.value.max; i++) {
    scales.push(1 / scale);
    scale /= 2;
  }
  return scales;
});

const mapSettings = computed(() => ({
  center: center.value,
  maxZoom: zoom.value.max,
  minZoom: zoom.value.min,
  attribution: 'BatObs.org',
  maxBounds: [
    [53.79936547201531, 3.197699334804843], // South west
    [50.64940671534544, 7.511752091085442], // North east
  ],
}));

const maps = computed(() => ({
  normal: L.tileLayer(buildMapTileUrl('normal'), mapSettings.value),
  aerial: L.tileLayer(buildMapTileUrl('aerial'), mapSettings.value),
}));

const tileEndpoint = computed(() => {
  const crs = 'EPSG:28992';
  const layerName = 'standaard';
  const format = 'png';
  return `${mapUrl.value}/brt/achtergrondkaart/wmts/v2_0/${layerName}/${crs}/{z}/{x}/{y}.${format}`;
});

function setDutchCrs() {
  dutchCrs.value = L.extend({}, L.CRS.Simple, {
    code: 'EPSG:28992',
    infinite: false,
    projection,
    transformation: new L.Transformation(1, 285401.920, -1, 903401.920),
    scale: (zoom: number) => {
      return scales.value[zoom];
    },
    zoom: (scale: number) => {
      return scales.value.indexOf(scale);
    },
  });
}

function initMap() {
  leafletMap.value = map.value.leafletObject;

  L.control.layers(maps.value).addTo(leafletMap.value);

  drawLayer.value = new L.FeatureGroup();
  leafletMap.value.addLayer(drawLayer.value);

  const customMarker = new L.Icon({
    iconAnchor: new L.Point(16, 40),
    iconSize: new L.Point(34, 54),
    iconUrl: markerImage,
  });

  // https://jsfiddle.net/10msdcon/2/
  drawControl.value = new L.Control.Draw({
    position: 'bottomleft',
    draw: {
      toolbar: {
        buttons: {
          polygon: 'Teken een meerhoek',
        },
      },
      polygon: props.allowed.includes('polygon') ? {
        icon: new L.DivIcon({
          iconSize: new L.Point(8, 8),
          className: 'leaflet-div-icon leaflet-editing-icon polygon-vertex',
        }),
        finishOn: 'dblclick',
        allowIntersection: false,
        drawError: {
          color: '#ff0000',
          message: '<strong>Oops!</strong> This polygon is not supported!',
        },
        shapeOptions: {
          color: '#e70d0d',
        },
        showArea: true,
        showLength: true,
        metric: ['km', 'm'],
      } : false,
      polyline: props.allowed.includes('polyline') ? {
        icon: new L.DivIcon({
          iconSize: new L.Point(8, 8),
          className: 'leaflet-div-icon leaflet-editing-icon polygon-vertex',
        }),
        finishOn: 'dblclick',
        allowIntersection: false,
        drawError: {
          color: '#ff0000',
          message: '<strong>Oops!</strong> This line is not supported!',
        },
        shapeOptions: {
          color: '#e70d0d',
        },
        showArea: true,
        showLength: true,
        metric: ['km', 'm'],
      } : false,
      circlemarker: false,
      circle: false,
      rectangle: false,
      marker: props.allowed.includes('point') ? {icon: customMarker} : false,
    },
  });

  // Events
  leafletMap.value.on('draw:created', event => drawCreated(event));

  leafletMap.value.addControl(drawControl.value);

  // TODO: Deze moeten gebruikt worden om bestaande drawings uit bijv. de DB te tonen
  // drawMarkings();
  // if (markings.length > 0 && markings[0].shape) {
  //   goToAverage();
  // }

  // if (isImage) {
  //   renderImage();
  // }
}

function addDrawControl() {
  leafletMap.value?.addControl(drawControl.value);
}

function removeDrawControl() {
  leafletMap.value?.removeControl(drawControl.value);
}

function toggleFullscreen() {
  if (!document.fullscreenElement) {
    mapIsFullscreen.value = true;

    return mapHolder.value?.requestFullscreen();
  }

  mapIsFullscreen.value = false;

  return document.exitFullscreen();
}

function centerUpdate(center: number[]) {
  currentCenter.value = center;
}

function zoomUpdate(updatedZoom: number) {
  zoom.value.current = updatedZoom;
}

function buildMapTileUrl(mapType) {
  if (mapType === 'aerial') {
    const version = 'v1_0';
    const matrix = 'EPSG:28992';
    const layers = {normal: 'brtachtergrondkaart', aerial: 'Actueel_ortho25'};
    return `${mapUrl.value}/hwh/luchtfotorgb/wmts/${version}/${layers[mapType]}/${matrix}/{z}/{x}/{y}.jpeg`;
  } else {
    const crs = 'EPSG:28992';
    const layerName = 'standaard';
    const format = 'png';
    return `${mapUrl.value}/brt/achtergrondkaart/wmts/v2_0/${layerName}/${crs}/{z}/{x}/{y}.${format}`;
  }
}

function drawCreated(event) {
  drawnShape.value = event.layer;

  emit('setMarking', event.layer.toGeoJSON());

  // Append layer to the map
  drawLayer.value.addLayer(event.layer);

  // Since only one polygon is allowed
  removeDrawControl();

  if (document.fullscreenElement) {
    toggleFullscreen();
  }
}

function removeDrawnLayer() {
  drawLayer.value.removeLayer(drawnShape.value);
  drawnShape.value = null;

  emit('setMarking', {geometry: {coordinates: null}});

  addDrawControl();
}

/*
function drawMarkings(cleared = false) {
  // Custom Ecologboek marker
  const customMarker = new L.Icon({
    iconAnchor: new L.Point(16, 40),
    iconSize: new L.Point(34, 54),
    iconUrl: markerImage,
  });
  if (markings) {
    let amount = markings.length;
    if (cleared) {
      amount = this.projectMarkings;
    }
    for (let i = 0, m = amount; i < m; i++) {
      const geoObject = JSON.parse(markings[i].shape);
      let color = '#4c98f1';
      if (i < projectMarkings) {
        color = '#59b659';
      }
      L.geoJSON(geoObject, {
        color: color,
        pointToLayer(geoJsonPoint, latlng) {
          return new L.Marker(latlng, {
            icon: customMarker,
          });
        },
      }).addTo(drawLayer.value);
    }
  }
}

function goToLocation(location) {
  const coordinates = extractPointCoords(location.centroide_ll);
  leafletMap.value.setView(coordinates, zoom.value.byType[location.type]);
}
*/

function goToCoordinates(lat, lng) {
  leafletMap.value.setView([lat, lng]);
}

/*
function goToMarker(geoObject) {
  const shapeType = geoObject.geometry.type.toLowerCase();
  switch (shapeType) {
    case 'point':
      leafletMap.value.setView(geoObject.geometry.coordinates.reverse(), zoom.value.byShape[shapeType]);
      break;
    case 'polygon':
      leafletMap.value.setView(L.geoJSON(geoObject).getBounds().getCenter(), zoom.value.byShape[shapeType]);
      break;
    case 'linestring':
      leafletMap.value.setView(L.geoJSON(geoObject).getBounds().getCenter(), zoom.value.byShape[shapeType]);
      break;
  }
}

function goToAverage() {
  // Highest of each used for calculation of the average coordinate and zoom
  // By getting the highest and lowest of each axis we're able to calculate an imaginary box where all the markers fit inside
  // using that we're able to calculate the "middle point" of all the markers and set the view to it. We also calculate the zoom
  let xHighest = 0;
  let yHighest = 0;
  let xLowest = 1000;
  let yLowest = 1000;
  for (let i = 0; i < zoomToAmount; i++) {
    const shape = JSON.parse(markings[i].shape).geometry;
    const coordinates = shape.coordinates;
    // If the marker is a Polygon instead of having just two coordinates it will have an array of coordinates,
    // therefore we loop through the coordinates array
    if (shape.type === 'Polygon' || shape.type === 'LineString') {
      // Calculations for polygon coordinates
      for (let e = 0; e < shape.coordinates[0].length; e++) {
        const xTemp = coordinates[0][e][1];
        const yTemp = coordinates[0][e][0];
        if (xTemp < xLowest) {
          xLowest = xTemp;
        }
        if (xTemp > xHighest) {
          xHighest = xTemp;
        }
        if (yTemp < yLowest) {
          yLowest = yTemp;
        }
        if (yTemp > yHighest) {
          yHighest = yTemp;
        }
      }
    } else {
      // Calculations for point coordinates
      const xTemp = coordinates[1];
      const yTemp = coordinates[0];
      if (xTemp < xLowest) {
        xLowest = xTemp;
      }
      if (xTemp > xHighest) {
        xHighest = xTemp + 0.02;
      } // A bit extra to make the marker visible instead of just the point of the marker
      if (yTemp < yLowest) {
        yLowest = yTemp;
      }
      if (yTemp > yHighest) {
        yHighest = yTemp;
      }
    }
  }
  // Now we get the average coordinates from all coordinates
  const x = (xHighest + xLowest) / 2;
  const y = (yHighest + yLowest) / 2;
  // Now we're going to calculate the zoom amount
  let zoom = 8;
  const distance = (yHighest - yLowest) + (xHighest - xLowest);
  zoom -= distance * 4.5 - (distance ^ distance);
  leafletMap.value.setView([x, y], zoom);
}

function extractPointCoords(point) {
  // Extract coordinates from a point
  const regex = /^(POINT\()([\d.]+) ([\d.]+)(\))$/gm;
  const m = regex.exec(point);
  return [m[3], m[2]];
}
*/

onMounted(() => {
  window.type = true;

  setDutchCrs();
});
</script>

<style>
.leaflet-like-button {
  @apply h-[33px] w-[33px] rounded-[4px] border-2 border-[rgba(0,0,0,0.2)] bg-white bg-clip-padding p-[2px] leading-none;
}

.leaflet-like-input {
  @apply h-[33px] w-[160px] rounded-[4px] border-2 border-[rgba(0,0,0,0.2)] bg-white bg-clip-padding p-[2px] pl-[6px] leading-none outline-none text-sm;
}
</style>
